import React from "react";
import {useMediaQuery} from "react-responsive";
import AboutXXS from "../components/pages/1_xxs/about_xxs";
import AboutXS from "../components/pages/2_xs/about_xs";
import AboutSM from "../components/pages/3_sm/about_sm";
import AboutMD from "../components/pages/4_md/about_md";
import AboutLG from "../components/pages/5_lg/about_lg";
import AboutXL from "../components/pages/6_xl/about_xl";
import AboutXXL from "../components/pages/7_xxl/about_xxl";
import AboutMega from "../components/pages/8_mega/about_mega";


export default function About() {
    const size_xxs= useMediaQuery({query: "(max-device-width: 479px)"});
    const size_xs= useMediaQuery({query: "(min-device-width: 480px) and (max-device-width: 577px)"});
    const size_sm= useMediaQuery({query: "(min-device-width: 578px) and (max-device-width: 767px)"});
    const size_md= useMediaQuery({query: "(min-device-width: 768px) and (max-device-width: 991px)" });
    const size_lg= useMediaQuery({query: "(min-device-width: 992px) and (max-device-width: 1199px)"});
    const size_xl= useMediaQuery({query: "(min-device-width: 1200px) and (max-device-width: 1599px)"});
    const size_xxl= useMediaQuery({query: "(min-device-width: 1600px) and (max-device-width: 1919px)"});
    const size_mega= useMediaQuery({query: "(min-device-width: 1920px)"});

    return (
        <div>
            {size_xxs && <AboutXXS />}
            {size_xs && <AboutXS />}
            {size_sm && <AboutSM />}
            {size_md && <AboutMD />}
            {size_lg && <AboutLG/>}
            {size_xl && <AboutXL />}
            {size_xxl && <AboutXXL />}
            {size_mega&& <AboutMega />}
        </div>
    );
}