import React from "react";


export function NameRM() {
    return(
        <div>
            <h4>Dr.-Ing. Richard Meyes</h4>
        </div>
    )
}

export function PositionRM() {
    return(
        <div>
            <h5 className="italic">Geschäftsführung</h5>
        </div>
    )
}

export function DescriptionRM() {
    return (
        <div>
            <p>
                Richard Meyes ist Co-Geschäftsführer und verantwortet gemeinsam mit Florian Müller das Tagesgeschäft
                der FusionIQ Systems GmbH.
            </p>
        </div>
    );
}

export function BioRM() {
    return (
        <div>
            <p>
                Richard Meyes studierte Physik an der RWTH Aachen und promovierte sowohl am Forschungszentrum Jülich im Bereich
                Neurowissenschaften und Medizin, an der RWTH Aachen im Bereich Maschinenbau und Produktionstechnik sowie
                an der Bergischen Universität Wuppertal im Bereich der Informatik und künstlichen Intelligenz.
                Seine Promotion zum Dr.-Ing. mit dem Thema "Transparency and Interpretability for Learned Representations
                of Artificial Neural Networks" legte er 2022 mit Auszeichnung an der Fakultät für Elektrotechnik,
                Informationstechnik und Medientechnik an der Bergischen Universität Wuppertal ab.
            </p>
        </div>
    );
}

export function ExpertiseRM() {
    return (
        <div>
            <h3>Expertise</h3>
            <p>
                <ul>
                    <li>KI für industrielle Anwendungen</li>
                    <li>Predictive Analytics für industrielle Sensordaten</li>
                    <li>Transparenz, Interpretierbarkeit & Erklärbarkeit für KI</li>
                </ul>
            </p>
        </div>
    );
}

