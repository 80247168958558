import React from "react";


export function NameMP() {
    return(
        <div>
            <h4>Dr.-Ing. Marion Purrio</h4>
        </div>
    )
}

export function PositionMP() {
    return(
        <div>
            <h5 className="italic">Analytics & Engineering</h5>
        </div>
    )
}

export function DescriptionMP() {
    return (
        <div>
            <p>
                Marion Purrio ist Teil des Analytics & Engineering Teams, Projektleiterin und Expertin für
                Digitalisierung, klassische Bildverarbeitung und Computer Vision Verfahren.
            </p>
        </div>
    );
}

export function BioMP() {
    return (
        <div>
            <p>
                Marion Purrio studierte an der RWTH Aachen Informatik. Sie arbeitete bereits während des Studiums im
                Institut für Schweißtechnik und Fügetechnik der RWTH Aachen, wo sie 2017 zum Thema „Prozessanalyse und
                -überwachung beim Metall-Schutzgasschweißen durch optische In-situ-Sensorsysteme“ promovierte.
                Seitdem beschäftigt sie sich mit Digitalisierungslösungen für Unternehmen mit Fokus auf Datenerfassung
                und –analyse fügetechnischer Serienfertigung.
            </p>
        </div>
    );
}

export function ExpertiseMP() {
    return (
        <div>
            <h3>Expertise</h3>
            <p>
                <ul>
                    <li>Digitalisierung in der Produktion</li>
                    <li>Systembetrachtung in der Schweiß- und Fügetechnik</li>
                    <li>Klassiche Bildverarbeitung & Computer Vision</li>
                </ul>
            </p>
        </div>
    );
}

