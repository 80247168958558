import React from "react";


export function NameGB() {
    return(
        <div>
            <h4>Dipl.-Ing. Guido Buchholz</h4>
        </div>
    )
}

export function PositionGB() {
    return(
        <div>
            <h5 className="italic">Analytics & Engineering</h5>
        </div>
    )
}

export function DescriptionGB() {
    return (
        <div>
            <p>
                Guido Buchholz ist Teil des Analytics & Engineering Teams und Technologie- und Prozessexperte für indsutrielle
                Anwendungen des Lichbogenschweißens.
            </p>
        </div>
    );
}

export function BioGB() {
    return (
        <div>
            <p>
                Kurzbio...
            </p>
        </div>
    );
}

export function ExpertiseGB() {
    return (
        <div>
            <h3>Expertise</h3>
            <p>
                <ul>
                    <li>Systembetrachtung in der Schweiß- und Fügetechnik</li>
                    <li>Prozessanalyse von Schweiß- und Fügeverfahren</li>
                    <li>Lichtbogenschweißen</li>
                </ul>
            </p>
        </div>
    );
}

