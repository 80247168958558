import React from "react";
import {useMediaQuery} from "react-responsive";
import SolutionsXXS from "../components/pages/1_xxs/solutions_xxs";
import SolutionsXS from "../components/pages/2_xs/solutions_xs";
import SolutionsSM from "../components/pages/3_sm/solutions_sm";
import SolutionsMD from "../components/pages/4_md/solutions_md";
import SolutionsLG from "../components/pages/5_lg/solutions_lg";
import SolutionsXL from "../components/pages/6_xl/solutions_xl";
import SolutionsXXL from "../components/pages/7_xxl/solutions_xxl";
import SolutionsMega from "../components/pages/8_mega/solutions_mega";


export default function Solutions() {
    const size_xxs= useMediaQuery({query: "(max-device-width: 479px)"});
    const size_xs= useMediaQuery({query: "(min-device-width: 480px) and (max-device-width: 577px)"});
    const size_sm= useMediaQuery({query: "(min-device-width: 578px) and (max-device-width: 767px)"});
    const size_md= useMediaQuery({query: "(min-device-width: 768px) and (max-device-width: 991px)" });
    const size_lg= useMediaQuery({query: "(min-device-width: 992px) and (max-device-width: 1199px)"});
    const size_xl= useMediaQuery({query: "(min-device-width: 1200px) and (max-device-width: 1599px)"});
    const size_xxl= useMediaQuery({query: "(min-device-width: 1600px) and (max-device-width: 1919px)"});
    const size_mega= useMediaQuery({query: "(min-device-width: 1920px)"});

    return (
        <div>
            {size_xxs && <SolutionsSM />}
            {size_xs && <SolutionsSM />}
            {size_sm && <SolutionsSM />}
            {size_md && <SolutionsMega />}
            {size_lg && <SolutionsMega/>}
            {size_xl && <SolutionsMega />}
            {size_xxl && <SolutionsMega />}
            {size_mega&& <SolutionsMega />}
        </div>
    );
}