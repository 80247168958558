import React from "react";


export function NameHT() {
    return(
        <div>
            <h4>Dr.-Ing. Hasan Tercan</h4>
        </div>
    )
}

export function PositionHT() {
    return(
        <div>
            <h5 className="italic">Wissenschaftlich-Technische Leitung "Analytics & Engineering"</h5>
        </div>
    )
}

export function DescriptionHT() {
    return (
        <div>
            <p>
                Hasan Tercan ist wissenschaftlich-technischer Leiter des Analytics & Engineering Teams und treibt die
                Entwicklung unserer Software- und Hardwarelösungen voran.
            </p>
        </div>
    );
}

export function BioHT() {
    return (
        <div>
            <p>
                Mit seinem Informatikstudium an der Technischen Universität Darmstadt und seiner Promotion an der RWTH
                Aachen und der Bergischen Universität Wuppertal bringt Herr Tercan fundierte Kenntnisse in
                angewandter KI und Data Science mit. Seine mit Auszeichnung abgeschlossene Dissertation befasste
                sich mit der KI-gestützten Qualitätssicherung in Fertigungsprozessen. Als Mitgründer
                der FusionIQ Systems GmbH ist es sein erklärtes Ziel, die Vorteile dieser Technologien
                für die Fügebranche gewinnbringend zu realisieren.
            </p>
        </div>
    );
}

export function ExpertiseHT() {
    return (
        <div>
            <h3>Expertise</h3>
            <p>
                <ul>
                    <li>Industrielle Data Science</li>
                    <li>Angewandte künstliche Intelligenz</li>
                    <li>Predictive Quality & Condition Monitoring</li>
                </ul>
            </p>
        </div>
    );
}

