import React from "react";
import {Col, Row, Divider} from "antd";
import portraitRM from "../../../images/portrait_RM.webp";
import portraitFM from "../../../images/portrait_FM.webp";
import portraitHT from "../../../images/portrait_HT.webp";
import portraitMA from "../../../images/portrait_MA.webp";
import portraitMP from "../../../images/portrait_MP.webp";
import portraitGB from "../../../images/portrait_GB.webp";
import {BioRM, DescriptionRM, ExpertiseRM, NameRM, PositionRM} from "../../portraits/RM";
import {BioFM, DescriptionFM, ExpertiseFM, NameFM, PositionFM} from "../../portraits/FM";
import {BioHT, DescriptionHT, ExpertiseHT, NameHT, PositionHT} from "../../portraits/HT";
import {BioMA, DescriptionMA, ExpertiseMA, NameMA, PositionMA} from "../../portraits/MA";
import {BioMP, DescriptionMP, ExpertiseMP, NameMP, PositionMP} from "../../portraits/MP";
import {BioGB, DescriptionGB, ExpertiseGB, NameGB, PositionGB} from "../../portraits/GB";
import {TextTeam, TitleTeam} from "../../text_content";
import colorScheme from "../../../scss/index.scss";
import portraitTeam from "../../../images/team.webp";


export default function TeamXXL() {
    return (
        <div>
            <Row justify="center" align="middle" className="padded-t50 padded-b50">
                <Col span={10}>
                    <Row>
                        <TitleTeam />
                    </Row>
                    <Row>
                        <TextTeam />
                    </Row>
                </Col>
                <Col span={8} offset={1}>
                    <img src={portraitTeam} className="img-portrait-team" alt="Logo"/>
                </Col>
            </Row>

            <Divider style={{borderColor: colorScheme.colorSoothingDarkBlue, borderWidth: 3}}/>

            <Row className="padded-t50">
                <Col flex="0 1 50%">
                    <Row align="middle" justify="center" className="padded-b25">
                        <Col flex="0 1 20%">
                            <Row>
                                <Col span={24}>
                                    <img src={portraitRM} className="img-portrait" alt="Logo"/>
                                </Col>
                            </Row>
                        </Col>
                        <Col flex="0 1 50%" offset={1}>
                            <Row>
                                <Col span={24}>
                                    <NameRM />
                                </Col>
                            </Row>
                            <Row style={{marginTop: "-15px"}}>
                                <Col span={24}>
                                    <PositionRM />
                                </Col>
                            </Row>
                            <Row>
                                <DescriptionRM />
                            </Row>
                        </Col>
                    </Row>
                    <Row align="top" justify="center">
                        <Col flex="0 1 75%">
                            <BioRM />
                        </Col>
                    </Row>
                    <Row align="top" justify="center">
                        <Col flex="0 1 75%">
                            <ExpertiseRM />
                        </Col>
                    </Row>
                </Col>

                <Col flex="0 1 50%">
                    <Row align="middle" justify="center" className="padded-b25">
                        <Col flex="0 1 20%">
                            <Row>
                                <Col span={24}>
                                    <img src={portraitFM} className="img-portrait" alt="Logo"/>
                                </Col>
                            </Row>
                        </Col>
                        <Col flex="0 1 50%" offset={1}>
                            <Row>
                                <Col span={24}>
                                    <NameFM />
                                </Col>
                            </Row>
                            <Row style={{marginTop: "-15px"}}>
                                <Col span={24}>
                                    <PositionFM />
                                </Col>
                            </Row>
                            <Row>
                                <DescriptionFM />
                            </Row>
                        </Col>
                    </Row>
                    <Row align="top" justify="center">
                        <Col flex="0 1 75%">
                            <BioFM />
                        </Col>
                    </Row>
                    <Row align="top" justify="center">
                        <Col flex="0 1 75%">
                            <ExpertiseFM />
                        </Col>
                    </Row>
                </Col>

            </Row>


            {/*<Divider style={{borderColor: colorScheme.colorSoothingDarkBlue, borderWidth: 1}}/>*/}

            <Row className="padded-t100">
                <Col flex="0 1 50%">
                    <Row align="middle" justify="center" className="padded-b25">
                        <Col flex="0 1 20%">
                            <Row>
                                <Col span={24}>
                                    <img src={portraitHT} className="img-portrait" alt="Logo"/>
                                </Col>
                            </Row>
                        </Col>
                        <Col flex="0 1 50%" offset={1}>
                            <Row>
                                <Col span={24}>
                                    <NameHT />
                                </Col>
                            </Row>
                            <Row style={{marginTop: "-15px"}}>
                                <Col span={24}>
                                    <PositionHT />
                                </Col>
                            </Row>
                            <Row>
                                <DescriptionHT />
                            </Row>
                        </Col>
                    </Row>
                    <Row align="top" justify="center">
                        <Col flex="0 1 75%">
                            <BioHT />
                        </Col>
                    </Row>
                    <Row align="top" justify="center">
                        <Col flex="0 1 75%">
                            <ExpertiseHT />
                        </Col>
                    </Row>
                </Col>

                <Col flex="0 1 50%">
                    <Row align="middle" justify="center" className="padded-b25">
                        <Col flex="0 1 20%">
                            <Row>
                                <Col span={24}>
                                    <img src={portraitMA} className="img-portrait" alt="Logo"/>
                                </Col>
                            </Row>
                        </Col>
                        <Col flex="0 1 50%" offset={1}>
                            <Row>
                                <Col span={24}>
                                    <NameMA />
                                </Col>
                            </Row>
                            <Row style={{marginTop: "-15px"}}>
                                <Col span={24}>
                                    <PositionMA />
                                </Col>
                            </Row>
                            <Row>
                                <DescriptionMA />
                            </Row>
                        </Col>
                    </Row>
                    <Row align="top" justify="center">
                        <Col flex="0 1 75%">
                            <BioMA />
                        </Col>
                    </Row>
                    <Row align="top" justify="center">
                        <Col flex="0 1 75%">
                            <ExpertiseMA />
                        </Col>
                    </Row>
                </Col>

            </Row>

            {/*<Divider style={{borderColor: colorScheme.colorSoothingDarkBlue, borderWidth: 1}}/>*/}

            <Row className="padded-t100 padded-b100">
                <Col flex="0 1 50%">
                    <Row align="middle" justify="center" className="padded-b25">
                        <Col flex="0 1 20%">
                            <Row>
                                <Col span={24}>
                                    <img src={portraitMP} className="img-portrait" alt="Logo"/>
                                </Col>
                            </Row>
                        </Col>
                        <Col flex="0 1 50%" offset={1}>
                            <Row>
                                <Col span={24}>
                                    <NameMP />
                                </Col>
                            </Row>
                            <Row style={{marginTop: "-15px"}}>
                                <Col span={24}>
                                    <PositionMP />
                                </Col>
                            </Row>
                            <Row>
                                <DescriptionMP />
                            </Row>
                        </Col>
                    </Row>
                    <Row align="top" justify="center">
                        <Col flex="0 1 75%">
                            <BioMP />
                        </Col>
                    </Row>
                    <Row align="top" justify="center">
                        <Col flex="0 1 75%">
                            <ExpertiseMP />
                        </Col>
                    </Row>
                </Col>

                <Col flex="0 1 50%">
                    <Row align="middle" justify="center" className="padded-b25">
                        <Col flex="0 1 20%">
                            <Row>
                                <Col span={24}>
                                    <img src={portraitGB} className="img-portrait" alt="Logo"/>
                                </Col>
                            </Row>
                        </Col>
                        <Col flex="0 1 50%" offset={1}>
                            <Row>
                                <Col span={24}>
                                    <NameGB />
                                </Col>
                            </Row>
                            <Row style={{marginTop: "-15px"}}>
                                <Col span={24}>
                                    <PositionGB />
                                </Col>
                            </Row>
                            <Row>
                                <DescriptionGB />
                            </Row>
                        </Col>
                    </Row>
                    <Row align="top" justify="center">
                        <Col flex="0 1 75%">
                            <BioGB />
                        </Col>
                    </Row>
                    <Row align="top" justify="center">
                        <Col flex="0 1 75%">
                            <ExpertiseGB />
                        </Col>
                    </Row>
                </Col>

            </Row>

            {/*<Divider style={{borderColor: colorScheme.colorSoothingDarkBlue, borderWidth: 1}}/>*/}

        </div>
    );
}

