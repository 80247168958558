import React from "react";


export function NameMA() {
    return(
        <div>
            <h4>Dipl.-Ing. Matthias Angerhausen</h4>
        </div>
    )
}

export function PositionMA() {
    return(
        <div>
            <h5 className="italic">Account Management & Vertrieb</h5>
        </div>
    )
}


export function DescriptionMA() {
    return (
        <div>
            <p>
                Matthias Angerhausen repräsentiert das immer offene Ohr für unsere Kunden.
                Er verantwortet den Vetrieb unserer Lösungen sowie die Kontakt- und Netzwerkpflege.
            </p>
        </div>
    );
}

export function BioMA() {
    return (
        <div>
            <p>
                Kurzbio...
            </p>
        </div>
    );
}

export function ExpertiseMA() {
    return (
        <div>
            <h3>Expertise</h3>
            <p>
                <ul>
                    <li>Systembetrachtung in der Schweiß- und Fügetechnik</li>
                    <li>Digitale Transformation von Prozessketten</li>
                    <li>Networking</li>
                </ul>
            </p>

        </div>
    );
}

