import React from "react";


export function NameFM() {
    return(
        <div>
            <h4>Florian Müller</h4>
        </div>
    )
}

export function PositionFM() {
    return(
        <div>
            <h5 className="italic">Geschäftsführung</h5>
        </div>
    )
}

export function DescriptionFM() {
    return (
        <div>
            <p>
                Florian Müller ist Co-Geschäftsführer und verantwortet gemeinsam mit Richard Meyes das Tagesgeschäft
                der FusionIQ Systems GmbH.
            </p>
        </div>
    );
}

export function BioFM() {
    return (
        <div>
            <p>
                Florian Müller studierte an der RWTH Aachen Raumfahrttechnik. Zudem ist er seit 2019 als Internationaler
                Schweißfachingenieur (SFI/IWE) qualifiziert. Von 2018 bis 2024 hat er am Institut für Schweißtechnik
                und Fügetechnik zu Wärmearmen Fügeverfahren geforscht, zuletzt in der Rolle als Teamleiter für das
                Metall-Ultraschallschweißen. Sein persönlicher Forschungsschwerpunkt ist der Einsatz und die Überwachung
                von stoffschlüssigen Fügeverfahren in elektrischen Anwendungen, von Batterie bis zum Elektromotor.
            </p>
        </div>
    );
}

export function ExpertiseFM() {
    return (
        <div>
            <h3>Expertise</h3>
            <p>
                <ul>
                    <li>Fügeverfahren für elektrische Anwendungen</li>
                    <li>Prozessdigitalisierung, Datenexploration und datengetriebenes Prozessverständnis</li>
                    <li>Systembetrachtung und datengetriebene Identifikation von Prozessstörgrößen</li>
                </ul>
            </p>
        </div>
    );
}

