import React from 'react';
import {Col, Divider, Row} from "antd";
import colorScheme from "../../../scss/index.scss";
import {
    TextSolutions11,
    TextSolutions12,
    TextSolutions13,
    TextSolutions14,
    TextSolutions21,
    TextSolutions22, TextSolutions23, TextSolutions24,
    TitleSolutions1,
    TitleSolutions11,
    TitleSolutions12,
    TitleSolutions13,
    TitleSolutions14,
    TitleSolutions2,
    TitleSolutions21,
    TitleSolutions22,
    TitleSolutions23, TitleSolutions24
} from "../../text_content";


export default function SolutionsSM() {
    return (
        <div>
            <Row justify="center" className="padded-t25">
                <Col
                    sm={{span: 18}}
                    xs={{span: 22}}
                >
                    <TitleSolutions1 />
                    <Row className="padded-t10">
                        <TitleSolutions11 />
                        <TextSolutions11 />
                    </Row>
                    <Row className="padded-t10">
                        <TitleSolutions12 />
                        <TextSolutions12 />
                    </Row>
                    <Row className="padded-t10">
                        <TitleSolutions13 />
                        <TextSolutions13 />
                    </Row>
                    <Row className="padded-t10">
                        <TitleSolutions14 />
                        <TextSolutions14 />
                    </Row>
                </Col>
            </Row>

            <Divider style={{borderColor: colorScheme.colorSoothingDarkBlue, borderWidth: 3}}/>

            <Row justify="center" className="padded-t10 padded-b25">
                <Col
                    sm={{span: 18}}
                    xs={{span: 22}}
                >
                    <TitleSolutions2 />
                    <Row className="padded-t10">
                        <TitleSolutions21 />
                        <TextSolutions21 />
                    </Row>
                    <Row className="padded-t10">
                        <TitleSolutions22 />
                        <TextSolutions22 />
                    </Row>
                    <Row className="padded-t10">
                        <TitleSolutions23 />
                        <TextSolutions23 />
                    </Row>
                    <Row className="padded-t10">
                        <TitleSolutions14 />
                        <TextSolutions14 />
                    </Row>
                </Col>
            </Row>
        </div>
    );
}