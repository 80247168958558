import React from "react";
import {Row, Col} from 'antd';


export default function PageFooterCopyright() {
    return (
        <div>
            <Row justify="center">
                <Col>
                    &copy; 2024 Copyright: {"FusionIQ Systems GmbH"}
                </Col>
            </Row>
        </div>
    );
}