import React from 'react';
import {Col, Divider, Row} from "antd";
import colorScheme from "../../../scss/index.scss";
import {
    TextSolutions11,
    TextSolutions12,
    TextSolutions13,
    TextSolutions14,
    TextSolutions21,
    TextSolutions22, TextSolutions23, TextSolutions24,
    TitleSolutions1,
    TitleSolutions11,
    TitleSolutions12,
    TitleSolutions13,
    TitleSolutions14,
    TitleSolutions2,
    TitleSolutions21,
    TitleSolutions22,
    TitleSolutions23, TitleSolutions24
} from "../../text_content";


export default function SolutionsMega() {
    return (
        <div>
            <Row justify="center" className="padded-t50 padded-b50">
                <Col
                    xxl={{span: 18}}
                    xl={{span: 20}}
                    lg={{span: 22}}
                    md={{span: 24}}
                    sm={{span: 24}}
                    xs={{span: 24}}
                >
                    <TitleSolutions1 />
                    <Row justify="space-evenly" className="padded-t50">
                        <Col span={10}>
                            <TitleSolutions11 />
                            <TextSolutions11 />
                        </Col>
                        <Col span={10}>
                            <TitleSolutions12 />
                            <TextSolutions12 />
                        </Col>
                    </Row>
                    <Row justify="space-evenly" className="padded-t50">
                        <Col span={10}>
                            <TitleSolutions13 />
                            <TextSolutions13 />
                        </Col>
                        <Col span={10}>
                            <TitleSolutions14 />
                            <TextSolutions14 />
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Divider style={{borderColor: colorScheme.colorSoothingDarkBlue, borderWidth: 3}}/>

            <Row justify="center" className="padded-t50">
                <Col
                    xxl={{span: 18}}
                    xl={{span: 20}}
                    lg={{span: 22}}
                    md={{span: 24}}
                    sm={{span: 24}}
                    xs={{span: 24}}
                >
                    <TitleSolutions2 />
                    <Row justify="space-evenly" className="padded-t50">
                        <Col span={10}>
                            <TitleSolutions21 />
                            <TextSolutions21 />
                        </Col>
                        <Col span={10}>
                            <TitleSolutions22 />
                            <TextSolutions22 />
                        </Col>
                    </Row>
                    <Row justify="space-evenly" className="padded-t50 padded-b50">
                        <Col span={10}>
                            <TitleSolutions23 />
                            <TextSolutions23 />
                        </Col>
                        <Col span={10}>
                            <TitleSolutions24 />
                            <TextSolutions24 />
                        </Col>
                    </Row>
                </Col>
            </Row>

        </div>
    );
}