import {BrowserRouter, Routes, Route} from "react-router-dom";
import Home from "../pages/home";
import About from "../pages/about";
import Solutions from "../pages/solutions";
import Impressum from "../pages/impressum";
import PrivacyPolicy from "../pages/privacy_policy";


export default function Routing() {
    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/solutions" element={<Solutions />} />
                    <Route path="/impressum" element={<Impressum />} />
                    <Route path="/datenschutz" element={<PrivacyPolicy />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}