import React from "react";


export function TitleHome() {
    return(
        <div>
            <h1>Das Beste aus zwei Welten</h1>

        </div>
    )
}

export function TextHome() {
    return(
        <div>
            <p>
                Datenbasierte, lernende Verfahren verschmelzen mit mehr als einem ganzen Jahrhundert traditioneller
                Ingenieurskunst aus der Welt der technischen Schweiß- und Fügeprozesse.
            </p>
        </div>
    )
}

export function TitleWhy() {
    return(
        <div>
            <h1>Unsere Vision</h1>
        </div>
    )
}

export function TextWhy() {
    return(
        <div>
            <p className="bold">
                Die Verschmelzung traditionaller Ingenieurskunst mit modernen, digitalen Lösungen.
            </p>
            <p>
                Wir streben nach einer Welt, in der die traditionelle, Deutsche Ingenieurskunst
                mit modernen, aus Daten lernenden Verfahren untrennbar miteinander verschmolzen sind.
                Unsere Mission: die heutigen Grenzen der industriellen Fertigung verschieben
                für die nächste Generation von Industrielösungen "Made in Germany".
            </p>
        </div>
    )
}

export function TitleHow() {
    return(
        <div>
            <h1>Unsere Philosophie</h1>
        </div>
    )
}

export function TextHow() {
    return(
        <div>
            <p className="bold">
                Auf Experten bauen und aus Daten lernen.
            </p>
            <p>
                Wir bauen auf das enorme Wissen und die jahrzehntelange Prozesserfahrung von Experten
                und bereichern diesen Wissenschatz durch datenzentrierte Empirie.
                So vereinen wir die Bereiche der Ingenieurs- und Datenwissenschaften und bieten Transparenz, Sichehrheit
                und Robustheit für die industrielle Wertschöpfung.
            </p>
        </div>
    )
}

export function TitleWhat() {
    return(
        <div>
            <h1>Unsere Leistungen</h1>
        </div>
    )
}

export function TextWhat() {
    return(
        <div>
            <p className="bold">
                Wir beraten und begleiten Unternehmen bei der Digitalisierung ihrer Schweiß- und Fügeprozesse.
            </p>
            <p>
                Mit einer Kombination aus Hardware- und Softwarelösungen gepaart mit Dienstleistungsangeboten begleiten
                wir Projekte im Bereich der Schweiß- und Fügetechnik von der initialen Konzeptionierung bis hin zur
                Umsetzung im Produktivbetrieb.
            </p>
        </div>
    )
}

export function CarouselTitleLichtbogenSchweissen() {
    return(
        <div>
            <h1 className="centered orange-text">Lichtbogenschweißen</h1>
        </div>
    )
}

export function CarouselTextLichtbogenSchweissen() {
    return(
        <div>
            <p className="light-text">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
                et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt
                ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo
                dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
            </p>
        </div>
    )
}

export function CarouselTitleLaserSchweissen() {
    return(
        <div>
            <h1 className="centered orange-text">Laserschweißen</h1>
        </div>
    )
}

export function CarouselTextLaserSchweissen() {
    return(
        <div>
            <p className="light-text">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
                et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt
                ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo
                dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
            </p>
        </div>
    )
}

export function TitlePartners() {
    return(
        <div>
            <h1 className="centered">Unsere Partner</h1>
        </div>
    )
}

export function TitleTeam() {
    return(
        <div>
            <h1 className="centered">Ein Team, eine Mission</h1>
        </div>
    )
}

export function TextTeam() {
    return(
        <div>
            <p>
                Das Team von FusionIQ bringt das Beste aus zwei Welten zusammen - fundierte Expertise aus der
                Fügetechnik und qualifizierter Sachverstand zum Maschinellen Lernen. Gemeinsam wollen wir Transparenz
                über die Qualität jeder einzelnen Schweißverbindung unser Kunden herstellen.
                Uns eint die Begeisterung für komplexe Wirkzusammenhänge und Systeme.
                <br></br>
                <br></br>
                Als Team verstehen wir sowohl die Fertigungsprozesse, an denen wir arbeiten, als auch die Werkzeuge,
                die wir einsetzen. Dabei können wir auf jahrelange eigene Erfahrung sowohl in der Forschung als
                auch in der  Industrie zurückgreifen. So können wir effizient lösungsorientiert arbeiten und uns
                auf Augenhöhe mit allen involvierten Stakeholdern austauschen - von der Produktion bis zur IT.
            </p>
        </div>
    )
}

export function TitleSolutions1() {
    return(
        <div>
            <h1 className="centered">Digitales Fügedatenmanagement</h1>
        </div>
    )
}

export function TitleSolutions11() {
    return(
        <div>
            <h3>Alle Daten, jederzeit und überall</h3>
        </div>
    )
}

export function TitleSolutions12() {
    return(
        <div>
            <h3>Datenaufbewahrung leicht gemacht</h3>
        </div>
    )
}

export function TitleSolutions13() {
    return(
        <div>
            <h3>Einblicke, die den Unterschied machen</h3>
        </div>
    )
}

export function TitleSolutions14() {
    return(
        <div>
            <h3>Rückvervolgbarkeit neu definiert</h3>
        </div>
    )
}

export function TextSolutions11() {
    return(
        <div>
            <p>
                Unsere Lösung bietet eine lückenlose Erfassung der Produktionsdaten im Fügeprozess.
                Dazu gehören Schweißstromquellen, Parameter, Sensordaten und Qualitätsmessungen.
                So erhalten Sie einen umfassenden Überblick über Ihre Produktionsprozesse und deren Qualität.
            </p>
        </div>
    )
}

export function TextSolutions12() {
    return(
        <div>
            <p>
                FusionIQ entwickelt maßgeschneiderte Datenbanken zur Speicherung Ihrer Produktions- und Qualitätsdaten.
                Damit ist eine sichere und zuverlässige Datenhaltung gewährleistet, die für Auswertungen genutzt werden kann.
            </p>
        </div>
    )
}

export function TextSolutions13() {
    return(
        <div>
            <p>
                Nutzen Sie unser benutzerfreundliches Dashboard zur retrospektiven Analyse des Produktionsprozesses.
                Erkennen Sie Optimierungspotenziale und treffen Sie fundierte Entscheidungen auf Basis der gesammelten Daten.
            </p>
        </div>
    )
}

export function TextSolutions14() {
    return(
        <div>
            <p>
                Erleben Sie lückenlose Rückverfolgbarkeit in Ihrer Produktion. Dank bauteilspezifischer Nachverfolgung
                sind alle Produktionsschritte detailliert dokumentiert, was Ihnen maximale Kontrolle und Sicherheit in
                Ihrer Qualitätssicherung bietet.
            </p>
        </div>
    )
}

export function TitleSolutions2() {
    return(
        <div>
            <h1 className="centered">Echtzeit-Qualitätskontrolle und Fehlererkennung</h1>
        </div>
    )
}

export function TitleSolutions21() {
    return(
        <div>
            <h3>Fehlerfrei durch Echtzeit-Überwachung</h3>
        </div>
    )
}

export function TitleSolutions22() {
    return(
        <div>
            <h3>Maßgeschneiderte Lösungen für Ihren Erfolg</h3>
        </div>
    )
}

export function TitleSolutions23() {
    return(
        <div>
            <h3>Erkennen und korrigieren, bevor es kritisch wird</h3>
        </div>
    )
}

export function TitleSolutions24() {
    return(
        <div>
            <h3>Konstanz in höchster Qualität</h3>
        </div>
    )
}

export function TextSolutions21() {
    return(
        <div>
            <p>
                Erleben Sie modernste Qualitätskontrolle beim Schweißen. Unsere Lösungen bieten nicht-invasive,
                zerstörungsfreie Fehlererkennung in Echtzeit - ohne Zeitverzögerung. Das garantiert reibungslose
                Produktionsprozesse und höchste Produktqualität.
            </p>
        </div>
    )
}

export function TextSolutions22() {
    return(
        <div>
            <p>
                FusionIQ entwickelt individuelle Systeme, die speziell auf die Anforderungen
                Ihres Unternehmens zugeschnitten sind. Dabei bieten wir das komplette Paket von der Sensorik über
                das Datenmanagement und die künstliche Intelligenz bis hin zur intuitiven Visualisierung auf einem
                Dashboard.
            </p>
        </div>
    )
}

export function TextSolutions23() {
    return(
        <div>
            <p>
                Unsere KI-Technologie erkennt potenzielle Fehler und Prozessabweichungen frühzeitig, so dass
                sie korrigiert werden können, bevor sie zu größeren Problemen führen. Das reduziert den Ausschuss
                und minimiert die Nacharbeit erheblich.
            </p>
        </div>
    )
}

export function TextSolutions24() {
    return(
        <div>
            <p>
                Durch die kontinuierliche Überwachung der Produktqualität stellt unser System sicher, dass Ihre
                Produkte stets den höchsten Standards entsprechen. Das Ergebnis? Eine gleichbleibende Produktqualität,
                weniger Ausschuss und erhebliche Kosteneinsparungen..
            </p>
        </div>
    )
}