import React, {useLayoutEffect, useRef, useState} from 'react';
import {Col, Row, Divider, Carousel, Button} from "antd";
import {RightOutlined, LeftOutlined} from '@ant-design/icons';
import colorScheme from "../../../scss/index.scss";
import {
    CarouselTextLaserSchweissen,
    CarouselTextLichtbogenSchweissen, CarouselTitleLaserSchweissen,
    CarouselTitleLichtbogenSchweissen, TextHome, TextHow, TextWhat, TextWhy, TitleHome, TitleHow,
    TitlePartners, TitleWhat, TitleWhy
} from "../../text_content";
import imageHome from "../../../images/fusioniq-home.webp"
import logo_tmdt from "../../../images/logo_tmdt.webp";
import logo_buw from "../../../images/logo_buw.webp";
import logo_fef from "../../../images/logo_fef.webp";
import logo_isf_rwth from "../../../images/logo_isf_rwth.webp";
import image_lichtbogenschweissen from "../../../images/image_lichtbogenschweißen.webp"
import image_laserschweissen from "../../../images/image_laserschweißen.webp"


export default function HomeMega() {
    return (
        <div>
            <Row justify="center" align="middle" className="padded-t50">
                <Col span={6}>
                    <Row>
                        <Col>
                            <TitleHome />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TextHome />
                        </Col>
                    </Row>
                    <Row>
                        <Button type="primary"
                                className="horizontally-centered"
                                onClick={event =>  window.location.href='/solutions'}
                        >
                            Zu unseren Lösungen!
                        </Button>
                    </Row>
                </Col>
                <Col span={6} offset={2}>
                    <img src={imageHome} className="logo-partner" alt="Logo"/>
                </Col>
            </Row>


            <Row justify="center" className="padded-t100 padded-b100">
                <Col span={18}>
                    <Row justify="space-between">
                        <Col span={7}>
                            <Row>
                                <Col span={24}>
                                    <TitleWhy />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <TextWhy />
                                </Col>
                            </Row>
                        </Col>
                        <Col span={7}>
                            <Row>
                                <Col span={24}>
                                    <TitleHow />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <TextHow />
                                </Col>
                            </Row>
                        </Col>
                        <Col span={7}>
                            <Row>
                                <Col span={24}>
                                    <TitleWhat />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <TextWhat />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>

            {/*<Row justify="center" className="bg-dark padded-t100 padded-b100">*/}
            {/*    <Col span={18}>*/}
            {/*        <Carousel dots={true}*/}
            {/*                  dotPosition="bottom"*/}
            {/*                  effect="fade"*/}
            {/*                  autoplaySpeed={5000}*/}
            {/*                  arrows={true}*/}
            {/*                  autoplay={true}*/}
            {/*                  nextArrow={<RightOutlined />}*/}
            {/*                  prevArrow={<LeftOutlined/>}*/}
            {/*        >*/}
            {/*            <div>*/}
            {/*                <CarouselLichtbogenSchweissen />*/}
            {/*            </div>*/}
            {/*            <div>*/}
            {/*                <CarouselLaserSchweissen />*/}
            {/*            </div>*/}
            {/*        </Carousel>*/}
            {/*    </Col>*/}
            {/*</Row>*/}

            <Divider style={{borderColor: colorScheme.colorSoothingDarkBlue, borderWidth: "3px"}}/>

            <Row justify="center">
                <Col span={18}>
                    <Row justify="space-between" className="padded-t100">
                        <Col span={24}>
                            <TitlePartners />
                        </Col>
                    </Row>
                    <Row justify="space-between" className="padded-t50 padded-b100">
                        <Col span={24}>
                            <Row align="middle" justify="space-evenly">
                                <Col span={3}>
                                    <a href="https://www.fef-aachen.de" target="_blank" rel="noreferrer">
                                        <img src={logo_fef} className="logo-partner" alt="Logo"/>
                                    </a>
                                </Col>
                                <Col span={5}>
                                    <a href="https://www.isf.rwth-aachen.de" target="_blank" rel="noreferrer">
                                        <img src={logo_isf_rwth} className="logo-partner" alt="Logo"/>
                                    </a>
                                </Col>
                                <Col span={4}>
                                    <a href="https://www.tmdt.uni-wuppertal.de" target="_blank" rel="noreferrer">
                                        <img src={logo_tmdt} className="logo-partner" alt="Logo"/>
                                    </a>
                                </Col>
                                <Col span={4}>
                                    <a href="https://www.uni-wuppertal.de" target="_blank" rel="noreferrer">
                                        <img src={logo_buw} className="logo-partner" alt="Logo"/>
                                    </a>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}


function CarouselLichtbogenSchweissen() {
    const [heightImage, setHeightImage] = useState(0)
    const refColHeight = useRef<HTMLDivElement>(null)
    useLayoutEffect(() => {
        if(refColHeight.current) {
            setHeightImage(refColHeight.current.clientHeight);
        }
    }, []);

    return (
        <div>
            <Row align="middle" justify="center">
                <Col flex="0 1 42%" ref={refColHeight}>
                    <Row>
                        <CarouselTitleLichtbogenSchweissen />
                    </Row>
                    <Row>
                        <CarouselTextLichtbogenSchweissen />
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Button type="primary"
                                    className="horizontally-centered"
                                    onClick={event =>  window.location.href='/solutions'}
                            >
                                Zu unseren Lösungen!
                            </Button>
                        </Col>
                    </Row>
                </Col>
                <Col flex="0 1 35%" offset={1}>
                    <img src={image_lichtbogenschweissen} className="carousel-image" alt="Ani" style={{height: heightImage}}/>
                </Col>
            </Row>
        </div>
    )
}


function CarouselLaserSchweissen() {
    const [heightImage, setHeightImage] = useState(0)
    const refColHeight = useRef<HTMLDivElement>(null)
    useLayoutEffect(() => {
        if(refColHeight.current) {
            setHeightImage(refColHeight.current.clientHeight);
        }
    }, []);

    return (
        <div>
            <Row align="middle" justify="center">
                <Col flex="0 1 42%" ref={refColHeight}>
                    <Row>
                        <CarouselTitleLaserSchweissen />
                    </Row>
                    <Row>
                        <CarouselTextLaserSchweissen />
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Button type="primary"
                                    className="horizontally-centered"
                                    onClick={event =>  window.location.href='/solutions'}
                            >
                                Zu unseren Lösungen!
                            </Button>
                        </Col>
                    </Row>
                </Col>
                <Col flex="0 1 35%" offset={1}>
                    <img src={image_laserschweissen} className="carousel-image" alt="Ani" style={{height: heightImage}}/>
                </Col>
            </Row>
        </div>
    )
}